import { openModal } from "@redq/reuse-modal";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { bus } from "react-icons-kit/fa/bus";
import { subway } from "react-icons-kit/fa/subway";
import { taxi } from "react-icons-kit/fa/taxi";
import { train } from "react-icons-kit/fa/train";
import { happy } from "react-icons-kit/icomoon/happy";
import Fade from "react-reveal/Fade";
import { Box, Flex, Heading } from "rebass";
import CallToAction from "../components/CallToAction";
import CityModal from "../components/CityModal";
import GoogleMap from "../components/GoogleMap";
import IconText from "../components/IconText";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Paragraph from "../components/Paragraph";
import Section, { SectionHeader } from "../components/Section";
import SEO from "../components/Seo";
import BorderRadiusBox from "../components/StyledComponent/BorderRadiusBox";

const AboutGreecePage = ({ data }) => {
  const handleCityModal = city => {
    openModal({
      config: {
        className: "city-modal",
        disableDragging: true,
        height: "70%",
        animationFrom: { transform: "translateY(100px)" }, // react-spring <Spring from={}> props value
        animationTo: { transform: "translateY(0)" }, //  react-spring <Spring to={}> props value
        enableResizing: {
          bottom: true,
          bottomLeft: true,
          bottomRight: true,
          left: true,
          right: true,
          top: true,
          topLeft: true,
          topRight: true
        },
        transition: {
          mass: 1,
          tension: 180,
          friction: 26
        }
      },
      component: CityModal,
      componentProps: { city },
      closeOnClickOutside: true
    });
  };

  return (
    <Layout transparentHeader>
      <SEO title="希臘簡介" />
      <PageHeader
        backgroundPosition="0 50%"
        backgroundImage={data.bg.childImageSharp.fluid}
      >
        <Fade delay={100}>
          <Heading color="white" fontSize={[5, 7]}>
            希臘簡介
          </Heading>
        </Fade>
      </PageHeader>

      <Section fluid title="最佳投資地段" pb={0}>
        <GoogleMap
          markers={data.markdown.frontmatter.cities}
          handleCityModal={handleCityModal}
        />
      </Section>

      <Section pb={[0, 0, "6rem"]}>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          flexDirection={["column-reverse", "column-reverse", "row-reverse"]}
        >
          <BorderRadiusBox
            mt={[4, 4, 0]}
            borderRadius={[0, 0, 12]}
            boxShadow={[
              "none",
              "none",
              "0px 0px 41px 22px rgba(0, 0, 0, 0.14)"
            ]}
            width={[1, 1, 1 / 2]}
          >
            <Img
              alt="天氣"
              fluid={data.markdown.frontmatter.images[0].childImageSharp.fluid}
            ></Img>
          </BorderRadiusBox>
          <Box
            width={[1, 1, 1 / 2]}
            // mx={[1]}
            py={[2, 0]}
            px={[2]}
            css={`
              display: table-cell;
              vertical-align: middle;
            `}
          >
            <SectionHeader mb={4}>天氣</SectionHeader>
            <IconText icon={happy}>春季(3-4月)平均溫度是7 ~ 16</IconText>
            <IconText icon={happy}>夏季(5-9月)平均溫度是18 ~ 30</IconText>
            <IconText icon={happy}>秋季(10-11月)平均溫度是17 ~ 29</IconText>
            <IconText icon={happy}>
              冬季(12-2月)平均溫度是7 ~ 15天氣寒冷
            </IconText>
          </Box>
        </Flex>
      </Section>

      <Section bg="secondary" pb={[0, 0, "6rem"]}>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          flexDirection={["column-reverse", "column-reverse", "row"]}
        >
          <BorderRadiusBox
            borderRadius={[0, 0, 12]}
            mt={[4, 4, 0]}
            boxShadow={[
              "none",
              "none",
              "0px 0px 41px 22px rgba(0, 0, 0, 0.14)"
            ]}
            width={[1, 1, 1 / 2]}
          >
            <Img
              fluid={data.markdown.frontmatter.images[1].childImageSharp.fluid}
              alt="交通"
            ></Img>
          </BorderRadiusBox>
          <Box
            width={[1, 1, 1 / 2]}
            // mx={[1]}
            pl={[2, 2, 8]}
            pr={[2]}
            py={[2, 0]}
            css={`
              display: table-cell;
              vertical-align: middle;
            `}
          >
            <SectionHeader mb={4}>交通</SectionHeader>
            <IconText icon={bus}>
              公車:一種是黃色車身的地上電車，一種是藍色巴士，兩種的車票可互通。藍巴士(EAS)行駛雅典市區內，是最便利的移動工具；而綠巴士(
              ISAP)行駛雅典的西面郊區
            </IconText>
            <IconText icon={taxi}>希臘計程車</IconText>
            <IconText icon={subway}>地鐵</IconText>
            <IconText icon={train}>郊區火車</IconText>
          </Box>
        </Flex>
      </Section>

      <Section title="教育">
        <BorderRadiusBox
          borderRadius={[0, 0, 12]}
          boxShadow={["none", "none", "0px 0px 41px 22px rgba(0, 0, 0, 0.14)"]}
          mx="auto"
          width={[1, 1, "70%"]}
        >
          <Img
            fluid={data.markdown.frontmatter.images[2].childImageSharp.fluid}
            alt="教育"
          ></Img>
        </BorderRadiusBox>
        <Box width={[1, 1]} mt={[0, 0, 3]}>
          <Paragraph>
            <p>
              希臘的學校分為公立與私立兩大類型。希臘實行12年公立學校免費教育(小學、中學、高中、大學、碩士都是免費)。希臘私立教育和國際教育資源豐富，且費用僅為國內的一半，孩子入學畢業後可直接就讀英美高校。
            </p>
            <p>
              未成年子女可以申請在希臘的公立學校免費就讀，也可以申請就讀私立學校，接受純正的英式教育。私立學校的學前教育費用為2000-3000歐元每學年，小學和初中為4000-6000歐元每學年，高中則為6000-9000歐元每學年。
            </p>
            <p>
              正常去在希臘定居的中國人，都是上希臘的國際學校。希臘國際學校的費用，幼兒班，5940歐元/年；小學，10020歐元/年；初中，11550歐元/年；高中，12030歐元/年。
              國際學校一般可以分為英式教育和美式教育兩類，較為有名的坎皮恩學校、聖勞倫斯學院、拜倫學院（純英式）、
              聖凱瑟琳學校都屬於英式教育，另外兩所則美國社區學校及雅典國際學校屬於美式教育。學生畢業後還可以在希臘當地參加A-level或者SAT的考試，選擇前往其他歐盟國家或者美國讀書。
            </p>
          </Paragraph>
        </Box>
      </Section>

      <Section bg="secondary" title="醫療">
        <Flex
          flexWrap="wrap"
          alignItems="center"
          flexDirection={["column", "column", "row-reverse"]}
        >
          <BorderRadiusBox
            borderRadius={[0, 0, 12]}
            boxShadow={[
              "none",
              "none",
              "0px 0px 41px 22px rgba(0, 0, 0, 0.14)"
            ]}
            mx="auto"
            width={[1, 1, "70%"]}
          >
            <Img
              fluid={data.markdown.frontmatter.images[3].childImageSharp.fluid}
              alt="醫療"
            ></Img>
          </BorderRadiusBox>
          <Box width={[1]} mt={[0, 0, 3]}>
            <Paragraph>
              <p>
                希臘的醫療保障體系在全球191個被調查的國家中位列第13位，在服務質量上是11位，希臘共有138家醫院和31000張病床，醫療資源極其豐富，移民希臘後，可享受與希臘公民同等的醫療服務。希臘醫療體系發達，凡是希臘的居民，有社會保險福利號碼者均有權享受公費醫療。
              </p>
              <p>
                通過買房移民希臘的投資者也可和當地居民一樣享受免費公立醫療服務。
                不過公立醫院就醫，很難避免人多、排隊時間長的情況。所以，你可以選擇私立醫院，醫生護士能夠流利地使用英語和希臘語。
              </p>

              <p>
                希臘移民購買醫療保險，即可享受私立醫院資源。希臘醫療保險價格都不高，每年僅幾百歐元左右，可覆蓋常見疾病、手術及住院所產生的費用。對於常住希臘的移民，也有保額更高、覆蓋整個歐盟的保險可供選擇。
              </p>
            </Paragraph>
          </Box>
        </Flex>
      </Section>

      <Section title="貨幣">
        <Flex flexWrap="wrap">
          <BorderRadiusBox
            borderRadius={[0, 0, 12]}
            boxShadow={[
              "none",
              "none",
              "0px 0px 41px 22px rgba(0, 0, 0, 0.14)"
            ]}
            mx="auto"
            width={[1, 1, "70%"]}
          >
            <Img
              fluid={data.markdown.frontmatter.images[4].childImageSharp.fluid}
              alt="貨幣"
            ></Img>
          </BorderRadiusBox>
          <Box width={[1]} mt={[0, 0, 3]}>
            <Paragraph>
              <p>
                歐元紙幣共分為5、10、20、50、100、200、500歐元7種面值，硬幣有1分、2分、5分、10分、20分、50分，1元，2元8種面值。
              </p>
              <p>
                2015年的經濟危機後，希臘目前的歐元現金比較緊張，所以希臘商戶最喜歡的是你直接付現金，甚至為此會給你很好的折扣。所以，適當帶好現金，並且千萬不要用500面值的大鈔！這一張大鈔等於希臘一般人民的月均收入了！所以，一定儘量用小面值的鈔票。
              </p>
              <p>
                希臘所有銀行對ATM機取現有嚴格限制，希臘公民每人每天目前只能取現120歐元，雖然沒有限制外國遊客的取現額度，但幾乎所有的ATM機都會排長隊，因此取現會很不方便。
              </p>
            </Paragraph>
          </Box>
        </Flex>
      </Section>

      <CallToAction />
    </Layout>
  );
};

export default AboutGreecePage;

export const query = graphql`
  {
    bg: file(relativePath: { eq: "bg-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    markdown: markdownRemark(fileAbsolutePath: { regex: "/about-greece/" }) {
      frontmatter {
        images {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cities {
          name
          area
          description
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
