import { navigate } from "gatsby";
import React from "react";
import Button from "../Button";
import Box from "../elements/Box";
import Heading from "../elements/Heading";
import MarkdownContent from "../Markdown";

const CityModal = ({ city }) => {
  return (
    <Box
      width={1}
      mx="auto"
      flexBox
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Heading textAlign="center" content={city.name}></Heading>
      <MarkdownContent
        css={`
          li {
            line-height: 1.5;
            // margin-bottom: 1rem;
          }
        `}
        content={city.description}
      ></MarkdownContent>

      <Button
        mx="auto"
        mt={[3, 4]}
        onClick={() =>
          navigate("/properties/", {
            state: {
              area: String(city.area)
            }
          })
        }
      >
        精選樓盤
      </Button>
    </Box>
  );
};

export default CityModal;
