import React, { FC } from "react";
import showdown from "showdown";

const converter = new showdown.Converter();

type Props = {
  content: string;
};

const MarkdownContent: FC<Props> = ({ content, ...rest }) => (
  <div
    {...rest}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
  />
);

export default MarkdownContent;
